import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TimeCountDown, TimeCountDownBetweenDirective} from '@gtd/directives/time-count-down-between';

@Component({
  selector: 'gtd-combo-booking-item',
  templateUrl: './combo-booking-item.component.html',
  styleUrls: ['./combo-booking-item.component.scss']
})
export class ComboBookingItemComponent implements OnInit {
  @Input() booking: any;
  @Input() supplierType: string;
  hotelDetail;
  isLoading: boolean;
  totalCustomer: number;
  timeCountDown: TimeCountDown;
  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective
  ) {}

  ngOnInit() {
    if (this.booking && this.booking.content) {
      this.hotelDetail = this.booking.content.groupBooking.items[0].hotelProduct;
      if(this.hotelDetail && this.hotelDetail.rooms.length > 0) {
        this.totalCustomer = this.hotelDetail.rooms[0].ratePlans[0].paxPrice.reduce(
          (acc, cur) => {
            return acc + cur.paxInfo.adultQuantity + cur.paxInfo.childQuantity;
          },
          0
        );
      }
    }
    if (this.booking.timeToLive) {
      this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
        this.booking.timeToLive
      );
    }
  }
  redirectPayment(event: Event, bookingNumber: string) {
    event.stopPropagation();
    this.router.navigate(['/booking/payment'], {
      queryParams: {
        bookingNumber: bookingNumber
      }
    });
  }

}
