import { Component, Input, OnInit } from '@angular/core';
import { DataOverview } from '@gtd/report-center/data-access/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'gtd-data-overview-box-list',
  templateUrl: './data-overview-box-list.component.html',
  styleUrls: ['./data-overview-box-list.component.scss']
})
export class DataOverviewBoxListComponent implements OnInit {
  @Input() dataOverview$: Observable<DataOverview[]>;
  @Input() type: 'overview' | 'flight' | 'hotel' | 'costAndPayment';
  lang: string;
  constructor() {}

  ngOnInit() {
    this.lang = localStorage.getItem('language');
  }
}
