import { Component, OnInit } from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'gtd-loading-card-booking',
  templateUrl: './loading-card.component.html',
  styleUrls: ['./loading-card.component.scss']
})
export class LoadingCardBookingComponent implements OnInit {
  config: SwiperConfigInterface;

  constructor(private deviceService: DeviceDetectorService) {
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 2 : this.deviceService.isTablet()? 2 : this.deviceService.isMobile()? 1 : 2,
      spaceBetween: 30,
      lazy: true
    };
  }

  ngOnInit() {
  }

}
