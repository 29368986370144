import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ReportCenterFacade, ReportCenterPayload} from '@gtd/report-center/data-access/store';
import {SnackbarComponent} from "@gtd/components/snackbar";
import {MatSnackBar} from "@angular/material";
import {ReportResourceService} from "@gtd/b2c-client";
import * as moment from 'moment';

@Component({
  selector: 'gtd-data-option-bar',
  templateUrl: './data-option-bar.component.html',
  styleUrls: ['./data-option-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataOptionBarComponent implements OnInit, AfterViewChecked {
  reportCenterPayload: ReportCenterPayload;
  loadingDownload: boolean;
  sortForm: FormGroup;
  sortMetric$ = this._reportCenterFacade.sortMetric$;
  @Output() isRefresh = new EventEmitter<boolean>(false);
  constructor(
    private _reportCenterFacade: ReportCenterFacade,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private reportResourceService: ReportResourceService,
  ) {
    this.sortForm = new FormGroup({
      sort: new FormControl()
    });
  }

  ngOnInit() {
    this.sortForm.get('sort').valueChanges.subscribe(sortMetric => {
      if (!sortMetric) return;
      this._reportCenterFacade.changeSortMetric(sortMetric);
    });
    this.sortForm.get('sort').patchValue(30);
    this.sortMetric$.subscribe(sortMetric => {
      if(sortMetric) {
        if (typeof sortMetric == 'number') {
          let toDate = new Date();
          let fromDate = new Date();
          fromDate.setDate(fromDate.getDate() - sortMetric);
          this.reportCenterPayload = {from: fromDate, to: toDate};
        } else {
          this.reportCenterPayload = {from: sortMetric.from, to: sortMetric.to};
        }
      }
    })
  }

  refreshData() {
    this.isRefresh.emit(true);
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  downloadData() {
    this.loadingDownload = true;
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: 'reportCenter.downloading-data', loading: true },
      panelClass: 'snackbar-loading',
      verticalPosition: 'top',
    });
    this.reportResourceService.getCAReportWithFormatUsingGET(
      this.reportCenterPayload.from, this.reportCenterPayload.to
    ).subscribe(blob => {
      this.loadingDownload = false;
      this._snackBar.dismiss();
      this.triggerDownload(blob,
        'DataExport_'
        +moment(this.reportCenterPayload.from).format('YYMMDD')
        +'-'
        +moment(this.reportCenterPayload.to).format('YYMMDD')
        +'.xlsx'
      );
    }, error => {
      this.loadingDownload = false;
      this._snackBar.dismiss()
      console.error('Error downloading the file.');
    });
  }
  private triggerDownload(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
