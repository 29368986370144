import {Component, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {GetPageBySlugService} from "@gtd/api-services/contentsrv";
import {DomSanitizer} from "@angular/platform-browser";
// import Swiper core and required modules
import SwiperCore, { Swiper, Virtual } from 'swiper';
import {SwiperComponent, SwiperConfigInterface} from "ngx-swiper-wrapper";

@Component({
  selector: 'gtd-banner-promo',
  templateUrl: './banner-promo.component.html',
  styleUrls: ['./banner-promo.component.scss']
})
export class BannerPromoComponent implements OnInit {
  config: SwiperConfigInterface;
  pageData: any;
  @ViewChild('newSwiper', { static: false }) newSwiper?: SwiperComponent;

  constructor(
    private deviceService: DeviceDetectorService,
    private getPageBySlugService: GetPageBySlugService,
  ) {
    this.getPageBySlugService.pageV1GetBySlugSlugGet('home-page-agent').subscribe(page => {
      if(page.success) this.pageData = page.page;
    });
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 3.2 : this.deviceService.isTablet()? 3 : this.deviceService.isMobile()? 1 : 3.2,
      spaceBetween: 30,
      // lazy: true,
      navigation: {
        nextEl: '.hot-promotion .swiper-next',
        prevEl: '.hot-promotion .swiper-prev'
      }
    };
  }

  ngOnInit() {

  }
  slideNext(){
    this.newSwiper.directiveRef.nextSlide(300);
  }
  slidePrev(){
    this.newSwiper.directiveRef.prevSlide(300);
  }
}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
