import { Component, OnInit } from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'gtd-loading-activity',
  templateUrl: './loading-activity.component.html',
  styleUrls: ['./loading-activity.component.scss']
})
export class LoadingActivityComponent implements OnInit {
  config: SwiperConfigInterface;

  constructor(private deviceService: DeviceDetectorService) {
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 4 : this.deviceService.isTablet()? 3 : this.deviceService.isMobile()? 1 : 4,
      spaceBetween: 30,
      lazy: true
    };
  }
  ngOnInit() {
  }

}
