import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataBarChartComponent } from './data-bar-chart.component';
import { BaseChartDirective, ChartsModule, ThemeService } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, ChartsModule, TranslateModule, MatTooltipModule],
  declarations: [DataBarChartComponent],
  exports: [DataBarChartComponent],
  providers: [BaseChartDirective, ThemeService]
})
export class DataBarChartModule {}
