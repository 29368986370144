import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { CredentialsService } from '@gtd/auth';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CovidDialogService,
  CovidDialogComponent
} from '@gtd/components/covid-dialog';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { AgenciesService } from '@gtd/services';
import {
  ReportCenterFacade,
  ReportCenterPayload
} from '@gtd/report-center/data-access/store';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {SnackbarComponent} from "@gtd/components/snackbar";

@Component({
  selector: 'gtd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  currentSlugSubject$ = new BehaviorSubject<string>('');
  currentSlug$ = this.currentSlugSubject$.asObservable();
  agencies$ = this._agenciesService.getAgencies;
  sortMetric$ = this._reportCenterFacade.sortMetric$;
  overviewMetric$ = this._reportCenterFacade.overviewMetric$;
  loaded$ = this._reportCenterFacade.loaded$;
  unsubscribe$ = new Subject();
  reportCenterPayload: ReportCenterPayload;
  cooperationClass;
  routesMeta = [
    {
      slug: 've-may-bay',
      index: 0,
      title: 'Vé Máy Bay Giá Rẻ, Nhiều Khuyến Mãi Hấp Dẫn 2022 | Gotadi',
      description:
        'Đặt vé máy bay giá rẻ VietJet, Vietnam Airlines, Bamboo Airways, Pacific...Thanh toán linh hoạt. Đặt online ngay săn vé máy bay giá rẻ cực dễ dàng trên Gotadi.'
    },
    {
      slug: 'khach-san',
      index: 1,
      title: 'Đặt Phòng Khách Sạn Giá Rẻ, Nhiều Khuyến Mãi 2022 | Gotadi ',
      description:
        'Đặt phòng khách sạn resort giá rẻ. Tiết kiệm lên đến 15% - Nhiều khuyến mãi - Giá đúng - Không chi phí ẩn - Thanh toán đa dạng - An toàn - Hỗ trợ 24/7.'
    },
    {
      slug: 'combo-du-lich',
      index: 2,
      title:
        'Combo du lịch vé máy bay và khách sạn giá tốt, nhiều khuyến mãi | Gotadi',
      description:
        'Đặt combo du lịch vé máy bay và khách sạn giá rẻ, tiết kiệm lên đến 20% - Nhiều khuyến mãi - Thanh toán đa dạng - An toàn - Hỗ trợ 24/7. Đặt ngay trên Gotadi.com'
    }
  ];

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private _covidDialogService: CovidDialogService,
    public credentialsService: CredentialsService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _agenciesService: AgenciesService,
    private _reportCenterFacade: ReportCenterFacade,
    private _translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // setTimeout(() => {
    //   if(!this.credentialsService.isAuthenticated()) {
    //     this.router.navigate(['/auth/login']);
    //   }
    // },100)
    this.cooperationClass = localStorage.getItem('cooperationClass');
  }

  ngOnInit() {
    this._translateService.onLangChange.subscribe(val =>
      this.refreshData(true)
    );
    this.checkTabSelected();
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptions.add(
        this._covidDialogService.loadData().subscribe(res => {
          if (
            res &&
            res[0] &&
            res[0].lastModifiedDate !== localStorage.getItem('covid')
          ) {
            const dialogRef = this.dialog.open(CovidDialogComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                messageType: 'success',
                heading: res[0].title,
                content: res[0].description,
                dataNotifications: res[0].dataNotifications,
                time: res[0].lastModifiedDate
              },
              width: '100vw',
              maxWidth: '100vw',
              position: {
                bottom: '0'
              },
              autoFocus: false,
              panelClass: 'covid-warning-component',
              disableClose: true
            });
          }
        })
      );
      this._agenciesService.getAgencies
        .pipe(
          switchMap(agencies =>
            this.sortMetric$.pipe(
              map(sortMetric => {
                return { agencies, sortMetric };
              })
            )
          ),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(mergedData => {
          const { agencies, sortMetric } = mergedData;
          if (!sortMetric || !agencies || agencies.cooperationClass !== 'ca')
            return;
          this.cooperationClass = agencies.cooperationClass;

          this.reportCenterPayload = {
            agencyId:
              agencies.agentAdminUsername !== 'democa@gotadi.com'
                ? agencies.agencyCode
                : 'A::1',
            interval: <number>sortMetric
          };
          if (typeof sortMetric == 'number') {
            this.reportCenterPayload.interval = sortMetric;
          } else {
            this.reportCenterPayload.from = sortMetric.from;
            this.reportCenterPayload.to = sortMetric.to;
          }
          this._reportCenterFacade.loadOverviewMetrics(
            this.reportCenterPayload
          );
        });
    }
  }

  refreshData(event) {
    if (!this.reportCenterPayload || !this.reportCenterPayload.agencyId) {
      return;
    }
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: 'reportCenter.refreshData', loading: true },
      panelClass: 'snackbar-loading',
      verticalPosition: 'top',
      duration: 2000
    });
    this._reportCenterFacade.loadOverviewMetrics(this.reportCenterPayload);
  }

  checkTabSelected() {
    this.activatedRoute.params.subscribe(params => {
      const currentRoute = params.slug
        ? this.routesMeta.find(route => route.slug === params.slug)
        : null;
      if (currentRoute) {
        this.currentSlugSubject$.next(currentRoute.slug);
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.unsubscribe();
    this._reportCenterFacade.resetMetric();
  }
}
