import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageInformationsComponent} from './page-informations.component';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faAngleRight, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FlexLayoutModule} from "@angular/flex-layout";

library.add(faChevronRight, faAngleRight);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    FlexLayoutModule
  ],
  declarations: [
    PageInformationsComponent
  ],
  exports: [PageInformationsComponent]
})
export class PageInformationsModule {}
