import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataOptionBarComponent } from './data-option-bar.component';
import {MatButtonModule, MatSelectModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportCenterStoreModule } from '@gtd/report-center/data-access/store';
import { TranslateModule } from '@ngx-translate/core';
import {SnackbarModule} from "@gtd/components/snackbar";
import {B2cApiClientModule} from "@gtd/b2c-client";

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ReportCenterStoreModule,
    TranslateModule,
    SnackbarModule,
    B2cApiClientModule,
    MatButtonModule
  ],
  declarations: [DataOptionBarComponent],
  exports: [DataOptionBarComponent]
})
export class DataOptionBarModule {}
