import {Component, OnDestroy, OnInit} from '@angular/core';
import {DEFAULT_PAGINATION_OFFSET, DEFAULT_PAGINATION_PAGE, fadeIn, fadeInOut} from "@gtd/helpers";
import {SearchBookingTransactionsFacade} from "@gtd/api-services/b2b-client";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'gtd-booking-transactions',
  templateUrl: './booking-transactions.component.html',
  styleUrls: ['./booking-transactions.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in'))
    ]),
    fadeIn,
    fadeInOut
  ]
})
export class BookingTransactionsComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  allSearchBookingTransactions$ = this.searchBookingTransactionsFacade.allSearchBookingTransactions$;
  loadedB2b$ = this.searchBookingTransactionsFacade.loaded$;
  config: SwiperConfigInterface;


  constructor(
    private searchBookingTransactionsFacade: SearchBookingTransactionsFacade,
    private deviceService: DeviceDetectorService
  ) {
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 1.75 : this.deviceService.isTablet()? 2 : this.deviceService.isMobile()? 1 : 1.75,
      spaceBetween: 30,
      lazy: true
    };
  }

  ngOnInit() {
    this.searchBookingTransactionsFacade.loadAll({
      ignoreSummary: true,
      filter: {
        status: 'BOOKED',
        paymentStatus: 'PENDING'
      },
      page: DEFAULT_PAGINATION_PAGE,
      size: DEFAULT_PAGINATION_OFFSET
    });
  }

  ngOnDestroy() {
    this.searchBookingTransactionsFacade.transactionDestroy();
  }

}
