import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ReportCenterPartialState } from './report-center.reducer';
import { reportCenterQuery } from './report-center.selectors';
import {
  LoadReportCenter,
  LoadReportCenterOverview,
  ReportCenterPayload,
  ResetReportCenter,
  SortReportCenter
} from './report-center.actions';

@Injectable()
export class ReportCenterFacade {
  loaded$ = this.store.pipe(select(reportCenterQuery.getLoaded));
  overviewMetric$ = this.store.pipe(
    select(reportCenterQuery.getOverViewMetric)
  );
  flightMetric$ = this.store.pipe(select(reportCenterQuery.getFlightMetric));
  hotelMetric$ = this.store.pipe(select(reportCenterQuery.getHotelMetric));
  costAndPaymentMetric$ = this.store.pipe(
    select(reportCenterQuery.getCostAndPaymentMetric)
  );
  sortMetric$ = this.store.pipe(select(reportCenterQuery.getSortMetric));
  selectedReportCenter$ = this.store.pipe(
    select(reportCenterQuery.getSelectedReportCenter)
  );

  constructor(private store: Store<ReportCenterPartialState>) {}

  loadMetrics(payload: ReportCenterPayload) {
    this.store.dispatch(new LoadReportCenter(payload));
  }

  loadOverviewMetrics(payload: ReportCenterPayload) {
    this.store.dispatch(new LoadReportCenterOverview(payload));
  }

  changeSortMetric(payload: { from: Date; to: Date } | number) {
    this.store.dispatch(new SortReportCenter(payload));
  }

  resetMetric() {
    this.store.dispatch(new ResetReportCenter());
  }
}
