import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BookingDTO} from '@gtd/api-services/booking-service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TimeCountDown, TimeCountDownBetweenDirective} from '@gtd/directives/time-count-down-between';

@Component({
  selector: 'gtd-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.scss']
})
export class BookingItemComponent implements OnInit, OnDestroy {
  @Input() booking: BookingDTO;
  @Input() supplierType: string;
  @Input() mode: string;
  @Input() isChecked: boolean;
  @Output() isSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  appName = localStorage.getItem('appName');
  bookingDTO = BookingDTO;
  isLoading: boolean;
  subscriptions = new Subscription();
  isShowDownloadInvoiceButton = false;
  isDisableDownloadInvoiceButton = false;
  timeCountDown: TimeCountDown;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective
  ) {}


  ngOnInit() {
    if (this.booking.timeToLive) {
      this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
        this.booking.timeToLive
      );
    }
  }

  redirectPayment(event: Event, bookingNumber: string) {
    event.stopPropagation();
    this.router.navigate(['/booking/payment'], {
      queryParams: {
        bookingNumber: bookingNumber
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
