import {Component, Input, OnInit} from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {I18nService} from '@gtd/i18nServiceB2b';


@Component({
  selector: 'gtd-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss']
})
export class LanguageChangeComponent implements OnInit {
  @Input() disabled = false;

  ngOnInit() {
    if (localStorage.getItem('currentLanguage')) {
      this.setLanguage(localStorage.getItem('currentLanguage'));
    }
  }

  constructor(
    private i18nService: I18nService
  ) { }

  setLanguage(language: string) {
    if (localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', language);
    }
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
