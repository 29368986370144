import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellB2bComponent } from './shell-b2b.component';
import {
  B2cClientModule,
  UserJwtControllerService,
  UtilResourceService
} from '@gtd/b2c-client';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faGithub,
  faInstagram,
  faMediumM,
  faSkype,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faBook,
  faBuilding,
  faCameraRetro,
  faCertificate,
  faExclamationTriangle,
  faGift,
  faHotel,
  faMobileAlt,
  faParachuteBox,
  faPlane,
  faSignOutAlt,
  faSortDown,
  faSuitcaseRolling,
  faUnlockAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { faAddressBook, faUser } from '@fortawesome/free-regular-svg-icons';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatRippleModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import {
  AvatarModule,
  ProfileModule,
  StateNotificationModule
} from '@gtd/user';
import { SnackbarModule } from '@gtd/components/snackbar';
import { AuthenticateModule } from '@gtd/auth';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-smart-popover';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { LanguageChangeComponent } from './header/language-change/language-change.component';
import { CopyRightComponent } from './footer/copy-right/copy-right.component';
import { FooterImgComponent } from './footer/footer-img/footer-img.component';
import { PaymentMethodComponent } from './footer/payment-method/payment-method.component';
import { ClientButtonModule } from '@gtd/components/client-button';
import { ScrollToTopModule } from '@gtd/scroll-to-top';
import { B2bClientApiModule } from '@gtd/api-services/b2b-client';
import { AgentAvatarResourceService } from '@gtd/api-services/agentsrv';
import { TemplateService } from '@gtd/services';
import { CustomerServiceModule } from '@gtd/api-services/customer-service';

library.add(
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube,
  faPlane,
  faHotel,
  faSuitcaseRolling,
  faBook,
  faFacebookSquare,
  faSkype,
  faCertificate,
  faParachuteBox,
  faCameraRetro,
  faGift,
  faMobileAlt,
  faSortDown,
  faUser,
  faUsers,
  faAlignLeft,
  faUnlockAlt,
  faSignOutAlt,
  faAddressBook,
  faExclamationTriangle,
  faBuilding
);

// @ts-ignore
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    FontAwesomeModule,
    MatTooltipModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    AvatarModule,
    SnackbarModule,
    AuthenticateModule,
    TranslateModule,
    MatRippleModule,
    PopoverModule,
    MatBottomSheetModule,
    StateNotificationModule,
    MatBadgeModule,
    ProfileModule,
    DialogMessageModule,
    ClientButtonModule,
    ScrollToTopModule,
    B2bClientApiModule,
    CustomerServiceModule,
    B2cClientModule
  ],
  exports: [ShellB2bComponent],
  declarations: [
    ShellB2bComponent,
    HeaderComponent,
    FooterComponent,
    MainNavbarComponent,
    LanguageChangeComponent,
    CopyRightComponent,
    PaymentMethodComponent,
    FooterImgComponent
  ],
  providers: [
    UserJwtControllerService,
    UtilResourceService,
    AgentAvatarResourceService,
    TemplateService
  ]
})
export class ShellB2bModule {}
