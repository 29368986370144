import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CredentialsService} from "@gtd/auth-b2b";
import {OverlayContainer} from "@angular/cdk/overlay";
import {TemplateService} from "@gtd/services";
import {GetUserProfileFacade} from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-shell-b2b',
  templateUrl: './shell-b2b.component.html',
  styleUrls: ['./shell-b2b.component.scss'],
  providers: [TemplateService]
})
export class ShellB2bComponent implements OnInit {
  @Input() urlB2b: string;
  pageInd: string;
  layout: 'SINGLE' | 'DUAL';
  layoutParam: 'SINGLE' | 'DUAL';
  appTemplate = 'agent-template';
  isShowFeedbackButton = false;

  constructor(
    private router: Router,
    private getUserProfileFacade: GetUserProfileFacade,
    private credentialsService: CredentialsService,
    private overlayContainer: OverlayContainer,
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.changeTheme(this.appTemplate);
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.layout) {
        this.layoutParam = queryParams.layout;
      }
    });

    this.router.events.subscribe(router_pr => {
      if (router_pr instanceof NavigationEnd) {
        if (router_pr && router_pr.url) {
          if (
            router_pr.url === '/' ||
            router_pr.url.includes('type=flight') ||
            router_pr.url.includes('type=hotel') ||
            router_pr.url.includes('type=combo')
          ) {
            this.pageInd = 'homepage';
          } else {
            this.pageInd = 'page-gtd-ct';
          }
          if (
            router_pr.url.includes('/auth/login')
            || router_pr.url.includes('/auth/registers')
            || router_pr.url.includes('/auth/request-password')
            || router_pr.url.includes('/booking/payment/payment-exception')
          ) {
            this.layout = "SINGLE";
          } else {
            this.layout = this.layoutParam ? this.layoutParam: "DUAL";
          }
        }
      }
    });

    this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
      if (userProfile && userProfile.id && userProfile.email && userProfile.email.includes('@gotadi.com')) {
        this.isShowFeedbackButton = true;
      }
    })
    setTimeout(() => {
      if (!this.credentialsService.isAuthenticated()) {
        this.pageInd = 'page-gtd-ct';
      }
    }, 100)
  }

  changeTheme(theme: string) {
    this.overlayContainer.getContainerElement().classList.add(theme);
  }

  ngOnInit() {
    this.templateService.getTemplateGtd.subscribe(template => {
      console.log(template)
      this.appTemplate = template;
    })
  }

}
