import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesComponent } from './activities.component';
import {B2bClientApiModule} from "@gtd/api-services/b2b-client";
import {MatCardModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {DatePipeModule} from "@gtd/components/date-pipe";
import {SwiperModule} from "ngx-swiper-wrapper";
import { LoadingActivityComponent } from './loading-activity/loading-activity.component';



@NgModule({
  declarations: [ActivitiesComponent, LoadingActivityComponent],
  exports: [
    ActivitiesComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    B2bClientApiModule,
    MatCardModule,
    FlexLayoutModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    DatePipeModule,
    SwiperModule,
  ]
})
export class ActivitiesModule { }
