import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { ReportCenterPartialState } from './report-center.reducer';
import {
  LoadReportCenter,
  ReportCenterLoaded,
  ReportCenterLoadError,
  ReportCenterActionTypes,
  LoadReportCenterOverview
} from './report-center.actions';
import { ReportResourceService } from '@gtd/api-services/b2b-client';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportCenterEffects {
  @Effect() loadReportCenter$ = this.dataPersistence.fetch(
    ReportCenterActionTypes.LoadReportCenter,
    {
      run: (action: LoadReportCenter, state: ReportCenterPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return forkJoin(
          this._reportResourceService.getOverviewMetricsUsingGET(
            action.payload.agencyId,
            action.payload.from,
            action.payload.to,
            action.payload.interval
          ),
          this._reportResourceService.getFlightMetricsUsingGET(
            action.payload.agencyId,
            action.payload.from,
            action.payload.to,
            action.payload.interval
          ),
          this._reportResourceService.getHotelMetricsUsingGET(
            action.payload.agencyId,
            action.payload.from,
            action.payload.to,
            action.payload.interval
          ),
          this._reportResourceService.getCostAndPaymentMetricsUsingGET(
            action.payload.agencyId,
            action.payload.from,
            action.payload.to,
            action.payload.interval
          )
        ).pipe(
          map(payload => ({
            type: ReportCenterActionTypes.ReportCenterLoaded,
            payload: {
              overviewMetric: payload[0],
              flightMetric: payload[1],
              hotelMetric: payload[2],
              costAndPaymentMetric: payload[3]
            }
          }))
        );
      },

      onError: (action: LoadReportCenter, error) => {
        console.error('Error', error);
        return new ReportCenterLoadError(error);
      }
    }
  );

  @Effect() loadReportCenterOverview$ = this.dataPersistence.fetch(
    ReportCenterActionTypes.LoadReportCenterOverview,
    {
      run: (
        action: LoadReportCenterOverview,
        state: ReportCenterPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._reportResourceService
          .getOverviewMetricsUsingGET(
            action.payload.agencyId,
            action.payload.from,
            action.payload.to,
            action.payload.interval
          )
          .pipe(
            map(payload => ({
              type: ReportCenterActionTypes.ReportCenterLoaded,
              payload: {
                overviewMetric: payload
              }
            }))
          );
      },
      onError: (action: LoadReportCenterOverview, error) => {
        console.error('Error', error);
        return new ReportCenterLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<ReportCenterPartialState>,
    private _reportResourceService: ReportResourceService
  ) {}
}
