import {
  AfterViewChecked,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { DataChart, DataOverview } from '@gtd/report-center/data-access/models';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';
import { DatePipe, DecimalPipe, DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gtd-data-bar-chart',
  templateUrl: './data-bar-chart.component.html',
  styleUrls: ['./data-bar-chart.component.scss'],
  providers: [DecimalPipe]
})
export class DataBarChartComponent implements OnInit, OnChanges {
  @Input() titleName: string;
  @Input() dataChart: DataChart[];
  @Input() colors = '#52B279';
  maxValue;
  data: any = {};
  lang = this._translateService.currentLang;

  constructor(
    private _datePipe: DatePipe,
    private _translateService: TranslateService
  ) {
    this.data = {
      labels: ['FLIGHT', 'HOTEL', 'COMBO', 'TOUR & TICKER'],
      datasets: [
        {
          label: 'Average spend by service',
          data: [65, 59, 80, 81],
          backgroundColor: this.colors,
          hoverBackgroundColor: this.colors,
          borderWidth: 0,
          borderColor: '',
          type: 'bar',
          yAxisID: 'A',
          fill: false
        }
      ],
      options: {
        responsive: true,
        tooltipTemplate: '<%= value %>',
        tooltipFillColor: 'rgba(0,0,0,0)',
        tooltipFontColor: '#444',
        tooltipEvents: [],
        tooltipCaretSize: 0,
        tooltips: {
          // callbacks: {
          //   label: function(tooltipItem, data) {
          //     return (
          //       data.datasets[tooltipItem.datasetIndex].label +
          //       ': ' +
          //       tooltipItem.yLabel.toLocaleString()
          //     );
          //   }
          // }
          enabled: false,

          custom: function(tooltipModel) {}
        },
        title: { display: false },
        legend: {
          display: false
        },
        hover: {
          mode: 'index',
          intersect: false,
          animationDuration: 0
        },
        animation: {
          duration: 1,
          onComplete: function() {
            let chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.fillStyle = '#000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function(dataset, i) {
              if (dataset.yAxisID == 'B') return;
              let meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                let data = dataset.data[index].toLocaleString();
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        layout: {
          padding: {
            top: 20
          }
        },
        scales: {
          yAxes: [
            {
              id: 'A',
              position: 'left',
              stacked: false,
              gridLines: {
                display: true,
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 7,
                precision: 0,
                callback: function(value, index, ticks) {
                  this.maxValue = ticks[0];
                  return value.toLocaleString();
                }
              }
            },
            {
              id: 'B',
              position: 'right',
              stacked: false,
              gridLines: {
                display: true,
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 2,
                display: false,
                precision: 0,
                callback: function(value, index, ticks) {
                  this.maxValue = ticks[0];
                  return value.toLocaleString();
                }
              }
            }
          ],
          xAxes: [
            {
              stacked: false,
              gridLines: { display: false },
              barPercentage: 0.4,
              categoryPercentage: 0.3
            }
          ]
        }
      }
    };
  }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.dataChart && changes.dataChart.currentValue) {
      const dataChart = changes.dataChart.currentValue;
      const lables = dataChart.map(data => {
        if (data.name.indexOf('Z') > -1) {
          const name = data.name.replace('Z', '');
          return this._datePipe.transform(name, 'dd/MM');
        } else if (
          this.titleName === 'bookingWindowOverTime' ||
          this.titleName === 'avgSpendsByService'
        ) {
          switch (data.name) {
            case 'AIR':
              return this._translateService.instant('reportCenter.flight.name');
            case 'HOTEL':
              return this._translateService.instant('reportCenter.hotel.name');
            case 'COMBO':
              return 'Combo';
            case 'TOURS':
              return 'Tour';
          }
        } else {
          return data.name;
        }
      });

      const values = dataChart.map(data => data.value);

      this.data.labels = lables;
      this.data.datasets[0].data = values;
      this.data.datasets[0].label = this._translateService.instant(
        'reportCenter.' + this.titleName
      );
    }
    if (changes && changes.titleName && changes.titleName.currentValue) {
      const values2 = this.dataChart.map(data => data.value2);
      // this.data.options.plugins.legend.labels = {
      //   filter: (legendItem, chartData) => legendItem.text !== 'dont show this'
      // };
      this.data.options.tooltips.custom = function(tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById(
          'chartjs-tooltip-' + changes.titleName.currentValue
        );

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderColor = '#000000';
          tooltipEl.style.borderWidth = 'thin';
          tooltipEl.style.borderStyle = 'solid';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);
          var tableRoot = tooltipEl.querySelector('table');
          let [description, value] = bodyLines[0][0].split(': ');
          value = +value;
          // tableRoot.innerHTML = innerHtml;
          const innerHtml = `<p style="margin-bottom:5px"><b>${description}</b></p>
          <ul style="padding-left:15px; white-space:nowrap; margin-bottom: 0"><li>${
            titleLines[0]
          } : <span style="color: #1AA260">${value.toLocaleString()}</span></li></ul>`;
          tooltipEl.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        var position = this._chart.canvas.getBoundingClientRect();
        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = tooltipModel.caretX + 'px';
        // tooltipEl.style.top = tooltipModel.caretY - 35 + 'px';
        tooltipEl.style.top = tooltipModel.caretY + 'px';
        tooltipEl.style.transform = 'translate(-50%, -100%)';
        tooltipEl.style.boxShadow = '4px 4px 20px rgba(0, 0, 0, 0.3)';
        tooltipEl.style.background = '#F9F9F9';
        tooltipEl.style.minWidth = '200px';
        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = '12px 20px';
        tooltipEl.style.zIndex = '88';
        tooltipEl.style.pointerEvents = 'none';
      };
      if (changes.titleName.currentValue === 'bookingWindowVsAvgPrice') {
        this.data.datasets[1] = {
          label: this._translateService.instant(
            'reportCenter.flight.avgMoneySpend'
          ),
          type: 'line',
          data: values2,
          yAxisID: 'B',
          borderColor: '#000',
          borderWidth: 2,
          backgroundColor: 'transparent',
          pointStyle: 'circle',
          pointBackgroundColor: '#000',
          hoverBackgroundColor: '#000',
          pointBorderColor: '#fff',
          pointRadius: 3,
          tension: 0.15
        };
        this.data.datasets[0].label = this._translateService.instant(
          'reportCenter.flight.bookingWindow'
        );
        this.data.datasets = [this.data.datasets[1], this.data.datasets[0]];
        this.data.options.layout = {
          padding: {
            top: 20,
            left: 40,
            right: 40
          }
        };
      }
    }
    if (changes && changes.colors && changes.colors.currentValue) {
      this.data.datasets[0].backgroundColor = this.colors;
      this.data.datasets[0].hoverBackgroundColor = this.colors;
    }
  }
}
