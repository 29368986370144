import { Component, Input, OnInit } from '@angular/core';
import { FlightMetric, OverviewMetric } from '@gtd/api-services/b2b-client';
import { DataOverview } from '@gtd/report-center/data-access/models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'gtd-data-overview',
  templateUrl: './data-overview.component.html',
  styleUrls: ['./data-overview.component.scss']
})
export class DataOverviewComponent implements OnInit {
  @Input() titleName: string;
  @Input() metric$: Observable<OverviewMetric>;
  @Input() isHomepage: boolean;
  lastUpdate: Date = new Date();
  dataOverview$: Observable<DataOverview[]>;
  constructor() {}

  ngOnInit() {
    this.dataOverview$ = this.metric$.pipe(
      map(metric => {
        if (metric && metric.lastUpdate) {
          this.lastUpdate = new Date(
            metric.lastUpdate.toString().replace('Z', '')
          );
        }
        const dataOverview: DataOverview[] = [];
        if (!metric.totalMoneySpend) {
          metric.totalMoneySpend = 0;
        }
        for (const key in metric) {
          if (Object.prototype.hasOwnProperty.call(metric, key)) {
            const value = metric[key];
            if (
              key == 'totalBookings' ||
              key == 'totalTrips' ||
              key == 'totalMoneySpend'
            )
              dataOverview.push(<DataOverview>{
                name: key,
                value: value,
                type: key === 'totalMoneySpend' ? 'currency' : 'number'
              });
          }
        }
        return dataOverview;
      })
    );
  }
}
