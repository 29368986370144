import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AuthenticateService, Credentials } from '@gtd/auth';
import { isPlatformBrowser } from '@angular/common';

const credentialsKey = 'credentials';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  private _credentials: Credentials | null = null;

  constructor(
    private authenticationService: AuthenticateService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if availableif (isPlatformBrowser(this.platformId)) {
    if (isPlatformBrowser(this.platformId) && request.url.indexOf('wp-json') === -1) {
      const savedCredentials =
        sessionStorage.getItem(credentialsKey) ||
        localStorage.getItem(credentialsKey);
      const credentialsGuest = localStorage.getItem('credentials-guest');
      const rClientTracking = localStorage.getItem('client-tracking');
      if (rClientTracking) {
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._credentials.token}`,
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        } else if (credentialsGuest) {
          request = request.clone({
            setHeaders: {
              Authorization: credentialsGuest,
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        } else {
          request = request.clone({
            setHeaders: {
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        }
      } else {
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._credentials.token}`
            }
          });
        } else if (credentialsGuest) {
          request = request.clone({
            setHeaders: {
              Authorization: credentialsGuest
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}
