import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
