import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromReportCenter from './report-center.reducer';
import { ReportCenterEffects } from './report-center.effects';
import { ReportCenterFacade } from './report-center.facade';
import { BASE_PATH, ReportResourceService } from '@gtd/api-services/b2b-client';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReportCenter.REPORTCENTER_FEATURE_KEY,
      fromReportCenter.reducer
    ),
    EffectsModule.forFeature([ReportCenterEffects])
  ],
  providers: [
    ReportCenterFacade,
    { provide: BASE_PATH, useValue: environment.api.b2bClient },
    ReportResourceService
  ]
})
export class ReportCenterStoreModule {}
