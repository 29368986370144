import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BookingTransactionsComponent} from "./booking-transactions.component";
import {B2bClientApiModule} from "@gtd/api-services/b2b-client";
import {BookingItemComponent} from "./booking-item/booking-item.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronRight, faHotel} from "@fortawesome/free-solid-svg-icons";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {DatePipeModule} from "@gtd/components/date-pipe";
import {MatButtonModule, MatCardModule} from "@angular/material";
import {TimeCountDownBetweenDirective} from "@gtd/directives/time-count-down-between";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ComboBookingItemComponent} from "./combo-booking-item/combo-booking-item.component";
import {SwiperModule} from "ngx-swiper-wrapper";
import {LoadingCardBookingComponent} from "./loading-card-booking/loading-card.component";
import { AllPipesModule } from '@gtd/pipes/all-pipes';


library.add(
  faHotel, faChevronRight
);


@NgModule({
  declarations: [
    BookingTransactionsComponent,
    BookingItemComponent,
    ComboBookingItemComponent,
    LoadingCardBookingComponent
  ],
  exports: [
    BookingTransactionsComponent,
    LoadingCardBookingComponent
  ],
  imports: [
    CommonModule,
    B2bClientApiModule,
    MatCardModule,
    FlexLayoutModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    DatePipeModule,
    SwiperModule,
    MatButtonModule,
    AllPipesModule
  ],
  providers: [TimeCountDownBetweenDirective]
})
export class BookingTransactionsModule { }
