import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { DataOverview } from '@gtd/report-center/data-access/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gtd-data-doughnut-chart',
  templateUrl: './data-doughnut-chart.component.html',
  styleUrls: ['./data-doughnut-chart.component.scss']
})
export class DataDoughnutChartComponent implements OnInit, OnChanges {
  @Input() titleName: string;
  @Input() dataChart: DataOverview[];
  data = {
    labels: ['Flight', 'Hotel', 'Combo Flight + Hotel', 'Tours & Ticket'],
    datasets: [
      {
        label: 'Spend by service',
        data: [65, 59, 80, 81],
        backgroundColor: ['#52B279', '#E97162', '#769DF7', '#FDC74F'],
        hoverBackgroundColor: ['#52B279', '#E97162', '#769DF7', '#FDC74F'],
        fill: false
      }
    ],
    options: {
      responsive: true,
      tooltipTemplate: '<%= value %>',
      tooltipFillColor: 'rgba(0,0,0,0)',
      tooltipFontColor: '#444',
      tooltipEvents: [],
      tooltipCaretSize: 0,
      tooltips: {
        enabled: false,
        custom: function(tooltipModel) {}
        // callbacks: {
        //   label: function(tooltipItem, data) {
        //     var dataset = data['datasets'][0];
        //     var value = dataset['data'][tooltipItem['index']].toLocaleString();
        //     return (
        //       ' ' +
        //       dataset.label +
        //       ' ' +
        //       data['labels'][tooltipItem['index']] +
        //       ' (' +
        //       value +
        //       ' VND)'
        //     );
        //   }
        // }
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true
        }
      },
      layout: {
        padding: {
          right: 100
        }
      },
      hover: {
        mode: 'index',
        intersect: false,
        animationDuration: 0
      }
    }
  };
  lang = this._translateService.currentLang;

  constructor(private _translateService: TranslateService) {}

  ngOnInit() {
    this._translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.dataChart && changes.dataChart.currentValue) {
      const dataChart = changes.dataChart.currentValue;
      const lables = dataChart.map(data => {
        switch (data.name) {
          case 'AIR':
            return this._translateService.instant('reportCenter.flight.name');
          case 'HOTEL':
            return this._translateService.instant('reportCenter.hotel.name');
          case 'COMBO':
            return 'Combo';
          case 'TOURS':
            return 'Tour';
        }
      });
      const values = dataChart.map(data => data.value);
      this.data.labels = lables;
      this.data.datasets[0].data = values;
      this.data.datasets[0].label = this._translateService.instant(
        'reportCenter.totalSpendByService'
      );
    }
    if (changes && changes.titleName && changes.titleName.currentValue) {
      this.data.options.tooltips.custom = function(tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById(
          'chartjs-tooltip-' + changes.titleName.currentValue
        );

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderColor = '#000000';
          tooltipEl.style.borderWidth = 'thin';
          tooltipEl.style.borderStyle = 'solid';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }
        // Set Text
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);
          var tableRoot = tooltipEl.querySelector('table');
          let [description, value] = bodyLines[0][0].split(': ');
          value = +value;
          // tableRoot.innerHTML = innerHtml;
          const innerHtml = `<p style="margin-bottom:5px"><b>${description}: </b><span style="color: #1AA260">${value.toLocaleString()}</span></p>`;
          tooltipEl.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        var position = this._chart.canvas.getBoundingClientRect();
        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = tooltipModel.caretX + 'px';
        tooltipEl.style.top = tooltipModel.caretY - 35 + 'px';
        tooltipEl.style.transform = 'translateX(-50%)';
        tooltipEl.style.boxShadow = '4px 4px 20px rgba(0, 0, 0, 0.3)';
        tooltipEl.style.background = '#F9F9F9';
        tooltipEl.style.minWidth = '200px';
        // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = '12px 20px';
        tooltipEl.style.zIndex = '88';
        tooltipEl.style.pointerEvents = 'none';
      };
    }
  }
}
