export const environment = {
  production: true,
  defaultLanguage: 'vi',
  supportedLanguages: ['en', 'vi'],
  appName: 'B2C',
  googleAnalyticsId: 'G-WTN5E2BT1G',
  api: {
    b2cClient: 'https://api.gotadi.com/web',
    b2bClient: 'https://b2b.gotadi.com',
    customerService: 'https://api.gotadi.com/web/customersrv',
    metaService: 'https://api.gotadi.com/web/metasrv',
    bookingService: 'https://api.gotadi.com/web/bookingsrv',
    covidNotifications: 'https://api.gotadi.com/web/ticketsrv/',
    inventoryService: 'https://api.gotadi.com/web/inventorysrv',
    agentsrv: 'https://api.gotadi.com/web/agentsrv',
    utilitysrv: 'https://api.gotadi.com/web/utilitysrv',
    metasrv: 'https://api.gotadi.com/web/metasrv',
    pricingsrv: 'https://api.gotadi.com/web/pricingsrv'
  },
  web: {
    version: 'Newface 1.0.2',
    phone: ['1900 9002', '(028) 62 857 857']
  },
  socialConfig: {
    facebookAppId: '840002496142196',
    fanpageUrl: '',
    skypeChat: 'retkpop',
    googleOAuthClientId:
      '51263142933-1pf3mvhhbrahsm4ha3e2t722co3ao5s3.apps.googleusercontent.com'
  },
  cdn: {
    cdnGotadi: 'https://38bb92d9c347dc7.cmccloud.com.vn',
    gtdBanner: 'https://www2.gotadi.com/',
    logoAirline:
      'https://booking.gotadi.com/Booking/AirBooking/images/AirLogos/'
  },
  downloadApp: {
    ios:
      'https://itunes.apple.com/us/app/gotadi-flight-booking/id1234937358?mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.vn.gotadi.mobileapp'
  },
  hotelTempUrl: 'https://www.gotadi.com/#/hotel-search?params='
};

