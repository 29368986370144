import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  REPORTCENTER_FEATURE_KEY,
  ReportCenterState
} from './report-center.reducer';

// Lookup the 'ReportCenter' feature state managed by NgRx
const getReportCenterState = createFeatureSelector<ReportCenterState>(
  REPORTCENTER_FEATURE_KEY
);

const getLoaded = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.loaded
);
const getError = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.error
);

const getAllReportCenter = createSelector(
  getReportCenterState,
  getLoaded,
  (state: ReportCenterState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.selectedId
);

const getOverViewMetric = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.list.overviewMetric
);

const getFlightMetric = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.list.flightMetric
);

const getHotelMetric = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.list.hotelMetric
);

const getSortMetric = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.list.sortMetric
);

const getCostAndPaymentMetric = createSelector(
  getReportCenterState,
  (state: ReportCenterState) => state.list.costAndPaymentMetric
);

const getSelectedReportCenter = createSelector(
  getAllReportCenter,
  getSelectedId,
  (reportCenter, id) => {
    const result = reportCenter;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const reportCenterQuery = {
  getLoaded,
  getError,
  getAllReportCenter,
  getSelectedReportCenter,
  getOverViewMetric,
  getFlightMetric,
  getHotelMetric,
  getCostAndPaymentMetric,
  getSortMetric
};
