import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataOverviewComponent } from './data-overview.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { DataOverviewBoxListModule } from '@gtd/report-center/ui/data-overview-box-list';
import { DataBarChartModule } from '@gtd/report-center/ui/data-bar-chart';
import { DataDoughnutChartModule } from '@gtd/report-center/ui/data-doughnut-chart';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataSummeriesModule } from '@gtd/report-center/ui/data-summeries';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DataOverviewBoxListModule,
    DataBarChartModule,
    DataDoughnutChartModule,
    RouterModule,
    TranslateModule,
    DataSummeriesModule
  ],
  declarations: [DataOverviewComponent],
  exports: [DataOverviewComponent]
})
export class DataOverviewModule {}
