import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataOverviewBoxListComponent } from './data-overview-box-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TranslateModule, MatTooltipModule],
  declarations: [DataOverviewBoxListComponent],
  exports: [DataOverviewBoxListComponent]
})
export class DataOverviewBoxListModule {}
