import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GetReferralFacade } from '@gtd/api-services/agentsrv';
import { GetHeaderSummaryFacade } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-data-summeries',
  templateUrl: './data-summeries.component.html',
  styleUrls: ['./data-summeries.component.scss']
})
export class DataSummeriesComponent implements OnInit, OnDestroy {
  @Input() revenue = false;
  loadedReferral$ = this.getReferralFacade.loaded$;
  allGetReferral$ = this.getReferralFacade.allGetReferral$;
  loaded$ = this.getHeaderSummaryFacade.loaded$;
  allGetHeaderSummary$ = this.getHeaderSummaryFacade.allGetHeaderSummary$;
  constructor(
    private getHeaderSummaryFacade: GetHeaderSummaryFacade,
    private getReferralFacade: GetReferralFacade
  ) {}

  ngOnInit() {
    this.getHeaderSummaryFacade.loadAll();
    this.getReferralFacade.loadAll();
  }

  ngOnDestroy(): void {
    this.getReferralFacade.destroyReferral();
    this.getHeaderSummaryFacade.resetAll();
  }
}
