import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'gtd-page-informations',
  templateUrl: './page-informations.component.html',
  styleUrls: ['./page-informations.component.scss']
})
export class PageInformationsComponent implements OnInit {
  @Input() titleH1: string;
  @Input() navigations: Navigations[];
  @Input() typeActive: number;

  constructor() { }

  ngOnInit() {
  }

}
class Navigations {
  title: string;
  url: string;
  routerLink: string;
  queryParams: any;
  type: string;
}
