import {
  CostAndPaymentMetric,
  FlightMetric,
  HotelMetric,
  OverviewMetric
} from '@gtd/api-services/b2b-client';
import {
  ReportCenterAction,
  ReportCenterActionTypes
} from './report-center.actions';

export const REPORTCENTER_FEATURE_KEY = 'reportCenter';

/**
 * Interface for the 'ReportCenter' data used in
 *  - ReportCenterState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface Entity {
  overviewMetric: OverviewMetric;
  flightMetric: FlightMetric;
  hotelMetric: HotelMetric;
  costAndPaymentMetric: CostAndPaymentMetric;
  sortMetric: { from: Date; to: Date } | number;
}

export interface ReportCenterState {
  list: Entity; // list of ReportCenter; analogous to a sql normalized table
  selectedId?: string | number; // which ReportCenter record has been selected
  loaded: boolean; // has the ReportCenter list been loaded
  error?: any; // last none error (if any)
}

export interface ReportCenterPartialState {
  readonly [REPORTCENTER_FEATURE_KEY]: ReportCenterState;
}

export const initialState: ReportCenterState = {
  list: {
    overviewMetric: {
      agencyCode: 'A::1_34264',
      from: '2022-11-13T15:50:00.123Z',
      to: '2022-12-13T15:50:00.124Z',
      lastUpdate: null,
      totalTrips: 0,
      totalBookings: 0,
      avgSpendsByService: [],
      totalSpendByService: []
    },
    flightMetric: null,
    hotelMetric: null,
    costAndPaymentMetric: null,
    sortMetric: 30
  },
  loaded: false
};

export function reducer(
  state: ReportCenterState = initialState,
  action: ReportCenterAction
): ReportCenterState {
  switch (action.type) {
    case ReportCenterActionTypes.ReportCenterLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case ReportCenterActionTypes.LoadReportCenter: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }
    case ReportCenterActionTypes.LoadReportCenterOverview: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }
    case ReportCenterActionTypes.SortReportCenter: {
      state = {
        ...state,
        list: { ...state.list, sortMetric: action.payload }
      };
      break;
    }
    case ReportCenterActionTypes.ResetReportCenter: {
      state = initialState;
    }
  }
  return state;
}
