import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BannerPromoComponent, SafeHtmlPipe} from './banner-promo.component';
import {B2bClientApiModule} from "@gtd/api-services/b2b-client";
import {MatCardModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {DatePipeModule} from "@gtd/components/date-pipe";
import {SwiperModule} from "ngx-swiper-wrapper";
import {GetPageBySlugService} from "@gtd/api-services/contentsrv";

@NgModule({
    declarations: [BannerPromoComponent, SafeHtmlPipe],
    exports: [
        BannerPromoComponent
    ],
    imports: [
      CommonModule,
      CommonModule,
      B2bClientApiModule,
      MatCardModule,
      FlexLayoutModule,
      FontAwesomeModule,
      TranslateModule,
      RouterModule,
      DatePipeModule,
      SwiperModule,
    ],
  providers: [GetPageBySlugService]
})
export class BannerPromoModule { }
