import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { PromptUpdateService } from '@gtd/pwa';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '../app/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { CredentialsService } from '@gtd/auth-b2b';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from '@gtd/services';
import { I18NLanguage } from './core/i18n.enum';

@Component({
  selector: 'gtd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Gotadi';
  subscription = new Subscription();
  aff_sid: string;
  urlB2b = environment.b2bSite;
  isLogged: boolean;

  constructor(
    private promptUpdateService: PromptUpdateService,
    private router: Router,
    private titleService: Title,
    private i18nService: I18nService,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: any,
    private _languageService: LanguageService
  ) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        let rClientTracking = localStorage.getItem('client-tracking');
        if (!rClientTracking) {
          rClientTracking = require('uuid').v4();
          localStorage.setItem('client-tracking', rClientTracking);
        }
        localStorage.setItem('appName', 'B2B');
      }
    } catch (e) {}

    this.activatedRoute.queryParams.subscribe(param => {
      if (Object.keys(param).length && param.auth) {
        this.isLogged = true;
        this.credentialsService.setCredentials(
          {
            token: param.auth,
            username: 'agent_gotadi'
          },
          true
        );
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.credentialsService.isAuthenticated()) {
        this.isLogged = true;
      } else {
        if (
          window.location.href.indexOf('login') < 0 &&
          window.location.href.indexOf('register') < 0 &&
          window.location.href.indexOf('request-password') < 0 &&
          window.location.href.indexOf('payment') < 0 &&
          window.location.href.indexOf('vui-choi-giai-tri') < 0
        ) {
          this.router.navigate(['/auth/login']);
        }
      }
    }, 10);

    const defaultLanguage = localStorage.getItem('language');
    this._languageService.setLanguage(
      defaultLanguage ? defaultLanguage : environment.defaultLanguage
    );

    this.i18nService.init(
      defaultLanguage
        ? I18NLanguage[defaultLanguage]
        : I18NLanguage[environment.defaultLanguage],
      environment.supportedLanguages
    );
    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(param => {
        if (param.language) {
          this.i18nService.language = param.language;
        }
      });
    }, 100);
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.subscription.unsubscribe();
  }
}
