import {Component, OnInit} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";
import {AgencyFinancialDTO, TransactionFinancialFacade} from "@gtd/api-services/b2b-client";
import {DEFAULT_PAGINATION_OFFSET, DEFAULT_PAGINATION_PAGE, fadeIn, fadeInOut} from "@gtd/helpers";

@Component({
  selector: 'gtd-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  animations: [
    fadeIn,
    fadeInOut
  ],
})
export class ActivitiesComponent implements OnInit {
  config: SwiperConfigInterface;
  groupTransactions: GroupTransactions[];
  allTransactionFinancial$ = this.transactionFinancialFacade.allTransactionFinancial$;
  loaded$ = this.transactionFinancialFacade.loaded$;

  constructor(
    private deviceService: DeviceDetectorService,
    private transactionFinancialFacade: TransactionFinancialFacade
  ) {
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 4 : this.deviceService.isTablet()? 3 : this.deviceService.isMobile()? 1 : 4,
      spaceBetween: 30,
      lazy: true
    };
  }

  ngOnInit() {
    this.transactionFinancialFacade.loadAll(
      {
        page: DEFAULT_PAGINATION_PAGE,
        size: DEFAULT_PAGINATION_OFFSET
      }
    );
  }

}
export interface GroupTransactions {
  date: string;
  transactions: Array<AgencyFinancialDTO>
}
