import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSummeriesComponent } from './data-summeries.component';
import { MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  declarations: [DataSummeriesComponent],
  exports: [DataSummeriesComponent]
})
export class DataSummeriesModule {}
