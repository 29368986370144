import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Optional, PLATFORM_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwiperModule } from 'ngx-swiper-wrapper';
import {
  MatIconModule,
  MatProgressBarModule,
  MatTabsModule
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NxModule } from '@nrwl/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PwaModule } from '@gtd/pwa';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FlightFormSearchModule, NewFlightFormSearchModule } from '@gtd/flight';
import { HotelFormSearchModule, NewHotelFormSearchModule } from '@gtd/hotel';
import { ComboFormSearchModule, NewComboFormSearchModule } from '@gtd/combovj';
import { IncreamentInputModule } from '@gtd/components/increament-input';
import { MemberSectionModule } from '@gtd/components/member-section';
import { ShellB2bModule } from '@gtd/shell-b2b';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard, AuthGuardNotLoggedIn } from './core/authGuard';
import { HomeComponent } from './home/home.component';
import { CookieService } from 'ngx-cookie-service';
import { BasicAuthInterceptor } from './core/basic-auth.interceptor';
import { SearchSectionComponent } from './home/search-section/search-section.component';
import { PageInformationsModule } from '@gtd/components/page-informations';
import { PoliciesComponent } from './home/policies/policies.component';
import { environment } from '../environments/environment';
import { PartnershipComponent } from './home/partnership/partnership.component';
import { CovidDialogModule } from '@gtd/components/covid-dialog';
import { SummariesComponent } from './home/summaries/summaries.component';
import { library } from '@fortawesome/fontawesome-svg-core';

import { DataOverviewModule } from '@gtd/report-center/ui/data-overview';
import { DataOptionBarModule } from '@gtd/report-center/ui/data-option-bar';
import {
  faAngleRight,
  faBalanceScale
} from '@fortawesome/free-solid-svg-icons';
import { faChartBar, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { B2bClientApiModule } from '@gtd/api-services/b2b-client';
import { AgentsrvStateModule } from '@gtd/api-services/agentsrv';
import { FlexModule } from '@angular/flex-layout';
import { BookingTransactionsModule } from './home/booking-transactions/booking-transactions.module';
import { ActivitiesModule } from './home/activities/activities.module';
import { BannerPromoModule } from './home/banner-promo/banner-promo.module';
import { isPlatformBrowser } from '@angular/common';
import { CRAWLER_AGENTS, VIEWER } from '@gtd/utilities';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { B2cClientModule } from '@gtd/b2c-client';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { HomeStoreModule } from './home/store/home-store.module';
import { DataSummeriesModule } from '@gtd/report-center/ui/data-summeries';
import { TourSearchModule } from '@gtd/extra/tour/ui/tour-search';
import { TourStoreModule } from '@gtd/extra/tour/data-access';

library.add(faBalanceScale, faChartBar, faHandshake, faAngleRight);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('@gtd/auth-b2b').then(m => m.AuthB2bModule)
    // canActivate: [AuthGuardNotLoggedIn]
  },
  {
    path: 'flight',
    loadChildren: () =>
      import('@gtd/flights/feature/shell').then(m => m.FlightsShellModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'checkout/flight',
    loadChildren: () => import('@gtd/checkout').then(m => m.CheckoutModule)
  },
  {
    path: 'checkout/hotel',
    loadChildren: () => import('@gtd/checkout').then(m => m.HotelCheckoutModule)
  },
  {
    path: 'checkout/combo',
    loadChildren: () => import('@gtd/checkout').then(m => m.ComboCheckoutModule)
  },
  {
    path: 'checkouts',
    loadChildren: () =>
      import('@gtd/checkouts/feature/shell').then(m => m.CheckoutShellModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('@gtd/member-ship').then(m => m.MemberShipModule)
  },
  {
    path: 'hotel',
    loadChildren: () => import('@gtd/hotel').then(m => m.HotelModule)
  },
  {
    path: 'hotels',
    loadChildren: () =>
      import('@gtd/hotels/feature/shell').then(m => m.HotelShellModule)
  },
  {
    path: 'combo',
    loadChildren: () => import('@gtd/combovj').then(m => m.ComboVjModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'booking/payment',
    loadChildren: () => import('@gtd/payment').then(m => m.PaymentModule)
  },
  {
    path: 'booking/payment/cancel',
    loadChildren: () => import('@gtd/payment').then(m => m.PaymentModule)
  },
  {
    path: 'booking/result',
    loadChildren: () =>
      import('@gtd/booking-result').then(m => m.BookingResultModule)
  },
  {
    path: 'user',
    loadChildren: () => import('@gtd/user').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'galleries',
    loadChildren: () => import('@gtd/galleries').then(m => m.GalleriesModule)
  },
  {
    path: 'report-center',
    loadChildren: () =>
      import('@gtd/report-center/feature/shell').then(
        m => m.ReportCenterShellModule
      )
  },
  {
    path: 've-may-bay/:slug',
    loadChildren: () =>
      import('@gtd/content/flight-content').then(m => m.FlightContentModule)
  },
  {
    path: 'khach-san/:slug',
    loadChildren: () =>
      import('@gtd/content/hotel-content').then(m => m.HotelContentModule)
  },
  {
    path: 'combo-du-lich/:slug',
    loadChildren: () =>
      import('@gtd/content/combo-content').then(m => m.ComboContentModule)
  },
  {
    path: 'diem-den/:slug',
    loadChildren: () =>
      import('@gtd/destination/feature-destination').then(
        m => m.FeatureDestinationModule
      )
  },
  {
    path: 'content',
    loadChildren: () =>
      import('@gtd/content-detail/feature/shell').then(
        m => m.ContentShellModule
      )
  },
  {
    path: 'approval-process',
    loadChildren: () =>
      import('@gtd/approval-process').then(m => m.ApprovalProcessModule)
  },
  {
    path: 'approval-management',
    loadChildren: () =>
      import('@gtd/approval-management').then(m => m.ApprovalManagementModule)
  },
  {
    path: 'approval-request/:id',
    loadChildren: () =>
      import('@gtd/approval-request').then(m => m.ApprovalRequestModule)
  },
  {
    path: 'vui-choi-giai-tri/:slug',
    loadChildren: () =>
      import('@gtd/extra/tour/feature/shell').then(m => m.ExtraTourShellModule)
  },
  {
    path: 'company-management',
    loadChildren: () =>
      import('@gtd/company-management/feature/shell').then(
        m => m.CompanyManagementShellModule
      )
  },
  {
    path: 'travel-policy',
    loadChildren: () =>
      import('@gtd/travel-policy/feature/shell').then(
        m => m.TravelPolicyShellModule
      )
  },
  {
    path: 'vui-choi-giai-tri',
    loadChildren: () =>
      import('@gtd/extra/tour/feature/shell').then(m => m.ExtraTourShellModule)
  },
  {
    path: 'markup-management',
    loadChildren: () =>
      import('@gtd/markup-management/feature/shell').then(
        m => m.MarkupManagementShellModule
      )
  },
  {
    path: '**',
    loadChildren: () =>
      import('@gtd/components/page-not-found').then(m => m.PageNotFoundModule)
  }
];

export function viewerFactory(platformId, req: any): string {
  if (isPlatformBrowser(platformId)) {
    return 'user';
  }
  const userAgent = (req.get('user-agent') || '').toLowerCase();
  const isCrawler = CRAWLER_AGENTS.some(
    crawlerAgent => userAgent.indexOf(crawlerAgent) !== -1
  );
  return isCrawler ? 'bot' : 'user';
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      // initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    }),
    ShellB2bModule,
    SwiperModule,
    MatIconModule,
    FontAwesomeModule,
    MatTabsModule,
    CovidDialogModule,
    PageInformationsModule,
    NxModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PwaModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    }),
    FlightFormSearchModule,
    HotelFormSearchModule,
    ComboFormSearchModule,
    IncreamentInputModule,
    MemberSectionModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    NewFlightFormSearchModule,
    NewHotelFormSearchModule,
    NewComboFormSearchModule,
    B2bClientApiModule,
    MatProgressBarModule,
    AgentsrvStateModule,
    FlexModule,
    BookingTransactionsModule,
    ActivitiesModule,
    BannerPromoModule,
    DataOverviewModule,
    DataOptionBarModule,
    B2cClientModule,
    HomeStoreModule,
    DataSummeriesModule,
    TourSearchModule,
    TourStoreModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    SearchSectionComponent,
    PoliciesComponent,
    PartnershipComponent,
    SummariesComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    AuthGuard,
    TranslateModule,
    AuthGuardNotLoggedIn,
    CookieService,
    BannerTypeService,
    { provide: 'env', useValue: environment },
    { provide: 'appName', useValue: environment.appName },
    {
      provide: VIEWER,
      useFactory: viewerFactory,
      deps: [PLATFORM_ID, [new Optional(), REQUEST]]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
