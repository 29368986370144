import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CredentialsService as CredentialsServiceB2B} from '@gtd/auth-b2b';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {DialogMessageComponent} from '@gtd/components/dialog-message';
import {MatDialog} from '@angular/material';
import {AccountFacade, UtilResourceService} from '@gtd/b2c-client';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AgencyResourceService, GetUserProfileFacade, UserProfileTO} from '@gtd/api-services/b2b-client';
import {fadeIn, fadeInOut} from '@gtd/helpers';
import {environment} from '@envB2b/environment';
import {AgentAvatarDTO, AgentAvatarResourceService} from '@gtd/api-services/agentsrv';
// @ts-ignore
import {AgenciesService, TemplateService} from '@gtd/services';
import {OverlayContainer} from '@angular/cdk/overlay';

@Component({
  selector: 'gtd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut, fadeIn],
  providers: [AgencyResourceService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isSticky: boolean;
  @Output() public sidenavToggle = new EventEmitter();
  @Input() urlB2b: string;

  isLogged: boolean;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');
  name: number = 0;
  timeoutHandler;
  getAvatart: AgentAvatarDTO;
  userProfile: UserProfileTO;
  loaded$ = this.getUserProfileFacade.loaded$;
  gtdAdminPortal;
  isViewFinancial = false;
  isConstantsMis = this.credentialsServiceB2B.authoritiesConstantsMis;
  enableApproval = this.credentialsServiceB2B.enableApprovalCheckout;
  isAgencyAdmin = this.credentialsServiceB2B.isAgencyAdmin;

  cooperationClass = localStorage.getItem('cooperationClass');
  constructor(
    private router: Router,
    private credentialsServiceB2B: CredentialsServiceB2B,
    private accountFacade: AccountFacade,
    public dialog: MatDialog,
    private overlayContainer: OverlayContainer,
    private utilResourceService: UtilResourceService,
    private deviceService: DeviceDetectorService,
    private getUserProfileFacade: GetUserProfileFacade,
    private agentAvatarResourceService: AgentAvatarResourceService,
    private agencyResourceService: AgencyResourceService,
    private templateService: TemplateService,
    private agenciesService: AgenciesService
  ) {
    if (this.credentialsServiceB2B.isAuthenticated() === true) {
      this.credentialsServiceB2B.setLoggedInUser(true);
    }
    this.name = -1;
    this.cooperationClass = localStorage.getItem('cooperationClass');
  }

  ngOnInit() {
    this.credentialsServiceB2B.getConstantsMis.subscribe(isConstantsMis =>
      isConstantsMis? this.isConstantsMis = isConstantsMis: this.credentialsServiceB2B.authoritiesConstantsMis
    );
    this.isViewFinancial = this.credentialsServiceB2B.authorityViewFinancialManagement;
    this.subscriptions.add(
      this.credentialsServiceB2B.getLoggedInUser.subscribe(loggedInUser => {
        this.isLogged = loggedInUser;
        if (loggedInUser) {
          this.getUserProfileFacade.loadAll(true);
        }
      })
    );
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(account => {
        if (account && account.userRefCode) {
          this.agencyResourceService
            .getAgencyByCodeUsingGET(account.userRefCode.slice(3).split('|')[0])
            .subscribe(agencies => {
              this.agenciesService.setAgencies(agencies);
              if (agencies && agencies.id) {
                localStorage.setItem(
                  'cooperationClass',
                  agencies.cooperationClass
                );
                this.cooperationClass = localStorage.getItem('cooperationClass');
                switch (agencies.cooperationClass) {
                  case 'ca':
                    this.templateService.setTemplateGtd('ca-template');
                    this.overlayContainer
                      .getContainerElement()
                      .classList.add('ca-template');
                    break;
                  case 'sa':
                    this.templateService.setTemplateGtd('agent-template');
                    this.overlayContainer
                      .getContainerElement()
                      .classList.add('agent-template');
                    break;
                  default:
                    this.templateService.setTemplateGtd('agent-template');
                    this.overlayContainer
                      .getContainerElement()
                      .classList.add('agent-template');
                    break;
                }
              }
            });
        }
      })
    );
    this.subscriptions.add(
      this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
        if (userProfile) {
          if (userProfile.status && userProfile.status === 417) {
            let dialogRef = this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [
                  {
                    btnColor: 'success',
                    btnText: 'user.re-login',
                    action: 'ReLogin'
                  }
                ],
                content: 'user.token-expired-message',
                heading: 'user.token-expired',
                messageType: 'success'
              },
              panelClass: 'dialog-do-confirm',
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(closeModal => {
              if (closeModal.action === 'ReLogin') {
                this.credentialsServiceB2B.logout().subscribe(result => {
                  if (result) {
                    this.isLogged = false;
                    this.userProfile = null;
                    window.open(this.router.url, '_self');
                  }
                });
                setTimeout(() => {
                  let params =
                    this.router.url !== '/auth/login' &&
                    this.router.url !== '/auth/register'
                      ? btoa(
                          encodeURIComponent(
                            JSON.stringify({ urlRedirect: this.router.url })
                          )
                        )
                      : null;
                  this.router.navigate(['/auth/login'], {
                    queryParams: {
                      params: params
                    }
                  });
                });
              }
            });
          } else {
            this.userProfile = userProfile;
            // if(userProfile.agencyCACodes && userProfile.agencyCACodes.length) {
            //   localStorage.setItem('agencyCode', userProfile.agencyCACodes[0].agencyCode);
            // } else {
            //   localStorage.removeItem('agencyCode');
            // }
            if(userProfile.agencyId === 1 || this.credentialsServiceB2B.authoritiesConstantsMis) {
              this.gtdAdminPortal =
                environment.b2bSite +
                '/#/login-token?token=' +
                this.credentialsServiceB2B.credentials.token;
            }
            this.isConstantsMis = this.credentialsServiceB2B.authoritiesConstantsMis;
            if (userProfile.id) {
              // @ts-ignore
              this.agencyResourceService
                .getAgencyUsingGET(userProfile.agencyId, true)
                .subscribe(agencies => {
                  this.agenciesService.setAgencies(agencies);
                  if (agencies && agencies.id) {
                    if(agencies.agencyCode) {
                      localStorage.setItem('agencyCode', agencies.agencyCode);
                    }
                    switch (agencies.cooperationClass) {
                      case 'ca':
                        this.templateService.setTemplateGtd('ca-template');
                        this.overlayContainer
                          .getContainerElement()
                          .classList.add('ca-template');
                        break;
                      case 'sa':
                        this.templateService.setTemplateGtd('agent-template');
                        this.overlayContainer
                          .getContainerElement()
                          .classList.add('agent-template');
                        break;
                      default:
                        this.templateService.setTemplateGtd('agent-template');
                        this.overlayContainer
                          .getContainerElement()
                          .classList.add('agent-template');
                        break;
                    }
                  }
                });
              this.agentAvatarResourceService
                .findAvatarsByProfileIdUsingGET(userProfile.id)
                .subscribe(getAvatart => {
                  this.getAvatart = getAvatart[getAvatart.length - 1];
                });
            }
          }
        } else {
          this.userProfile = userProfile;
        }
      })
    );
  }

  onMenuToggle(trigger) {
    if (this.deviceService.isDesktop()) {
      trigger.openMenu();
    }
  }

  public mouseup() {
    if (this.timeoutHandler) {
      if (this.name === 0) {
        this.router.navigate(['/']);
      }
      setTimeout(() => {
        clearInterval(this.timeoutHandler);
        this.name = 0;
        this.timeoutHandler = null;
      });
    }
  }

  public mousedown() {
    this.timeoutHandler = setInterval(() => {
      this.name += 1;
      if (this.name === 2) {
        const rClientTracking = localStorage.getItem('client-tracking');
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [{ btnText: 'global.close' }],
            content: 'Client ID: ' + rClientTracking,
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          disableClose: true
        });
        this.utilResourceService.createSupportRequestUsingGET().subscribe();
      }
    }, 1000);
  }

  loggin() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/login'], {
      queryParams: {
        params: params
      }
    });
  }

  register() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/register'], {
      queryParams: {
        params: params
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  userLogout() {
    this.getUserProfileFacade.destroyUser();
    this.subscriptions.add(
      this.credentialsServiceB2B.logout().subscribe(result => {
        if (result) {
          this.agenciesService.setAgencies(null);
          this.isLogged = false;
          this.userProfile = null;
          this.router.navigate(['/auth/login']);
        }
      })
    );
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };
}
