import { Action } from '@ngrx/store';
import { Entity } from './report-center.reducer';

export enum ReportCenterActionTypes {
  LoadReportCenter = '[ReportCenter] Load ReportCenter',
  ReportCenterLoaded = '[ReportCenter] ReportCenter Loaded',
  ReportCenterLoadError = '[ReportCenter] ReportCenter Load Error',
  SortReportCenter = '[ReportCenter] Sort ReportCenter',
  ResetReportCenter = '[ReportCenter] Reset ReportCenter',
  LoadReportCenterOverview = '[ReportCenter] Load ReportCenterOverview'
}

export interface ReportCenterPayload {
  agencyId?: string;
  from?: Date;
  to?: Date;
  interval?: number;
}

export class LoadReportCenter implements Action {
  readonly type = ReportCenterActionTypes.LoadReportCenter;
  constructor(public payload: ReportCenterPayload) {}
}

export class LoadReportCenterOverview implements Action {
  readonly type = ReportCenterActionTypes.LoadReportCenterOverview;
  constructor(public payload: ReportCenterPayload) {}
}

export class ReportCenterLoadError implements Action {
  readonly type = ReportCenterActionTypes.ReportCenterLoadError;
  constructor(public payload: any) {}
}

export class ReportCenterLoaded implements Action {
  readonly type = ReportCenterActionTypes.ReportCenterLoaded;
  constructor(public payload: Entity) {}
}

export class SortReportCenter implements Action {
  readonly type = ReportCenterActionTypes.SortReportCenter;
  constructor(public payload: { from: Date; to: Date } | number) {}
}

export class ResetReportCenter implements Action {
  readonly type = ReportCenterActionTypes.ResetReportCenter;
}

export type ReportCenterAction =
  | LoadReportCenter
  | LoadReportCenterOverview
  | ReportCenterLoaded
  | ReportCenterLoadError
  | SortReportCenter
  | ResetReportCenter;

export const fromReportCenterActions = {
  LoadReportCenter,
  LoadReportCenterOverview,
  ReportCenterLoaded,
  ReportCenterLoadError,
  SortReportCenter,
  ResetReportCenter
};
