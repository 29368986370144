import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromBanner from './banner/banner.reducer';
import { BannerEffects } from './banner/banner.effects';
import { BannerFacade } from './banner/banner.facade';
import { BannerTypeService } from '@gtd/api-services/newface-banner';

@NgModule({
  imports: [
    StoreModule.forFeature(fromBanner.BANNER_FEATURE_KEY, fromBanner.reducer),
    EffectsModule.forFeature([BannerEffects])
  ],
  providers: [BannerTypeService, BannerFacade]
})
export class HomeStoreModule {}
