import { Component, OnInit } from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";
import {GetHeaderSummaryFacade} from "@gtd/api-services/b2b-client";
import {Subscription} from "rxjs";
import {GetReferralFacade} from "@gtd/api-services/agentsrv";
import {fadeIn, fadeInOut} from "@gtd/helpers";

@Component({
  selector: 'gtd-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.scss'],
  animations: [
    fadeInOut,
    fadeIn
  ]
})
export class SummariesComponent implements OnInit {
  config: SwiperConfigInterface;
  subscriptions = new Subscription();
  loadedReferral$ = this.getReferralFacade.loaded$;
  allGetReferral$ = this.getReferralFacade.allGetReferral$;
  loaded$ = this.getHeaderSummaryFacade.loaded$;
  allGetHeaderSummary$ = this.getHeaderSummaryFacade.allGetHeaderSummary$;

  constructor(
    private deviceService: DeviceDetectorService,
    private getHeaderSummaryFacade: GetHeaderSummaryFacade,
    private getReferralFacade: GetReferralFacade
  ) {
    this.getHeaderSummaryFacade.loadAll();
    this.getReferralFacade.loadAll();
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 4 : this.deviceService.isTablet()? 2 : this.deviceService.isMobile()? 1 : 4,
      spaceBetween: 10,
      lazy: true
    };
  }

  ngOnInit() {

  }

}
