import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CredentialsService} from "@gtd/auth-b2b";
import {Router} from "@angular/router";

@Component({
  selector: 'gtd-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.scss']
})
export class MainNavbarComponent implements OnInit, AfterViewChecked {
  @Output() sidenavClose = new EventEmitter();
  @Input() urlB2b: string;
  isLogged: boolean;

  menus = [
    {
      title: 'navbar.homepage',
      routerLink: '/',
      type: 'ISPAGE'
    },

    {
      title: 'navbar.promotions',
      url: 'https://tour.gotadi.com/category/khuyen-mai',
      target: '_blank',
      icon: 'gift',
      iconPrefix: 'fas',
      type: 'LINK'
    }
  ];
  constructor(
    private credentialsService: CredentialsService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.credentialsService.isAuthenticated() === true) {
      this.isLogged = true;
    }
  }

  ngOnInit() {}

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  userLogout() {
    this.credentialsService.isLoggedIn.subscribe(login => this.isLogged = login);
    this.credentialsService.logout().subscribe(result => {
      if(result) {
        this.isLogged = false;
        this.onSidenavClose();
        this.router.navigate(['/auth/login']);
      }
    })
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
